import React from "react";
const Collection = () => {
    return (
    <>
        <header className="masthead" style={{color: '#FFF'}}>
            <div className="container px-5 text-center">
                <h1 className="fw-bolder">Ranch Collection</h1>
                {/* <p className="lead">The Benedicto cigar is a product of family tradition</p> */}
            </div>
        </header>
        <section>
            <div className="container px-4">
                <div className="divider-custom">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon" style={{width: '5rem'}}>
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img src="/assets/img/header_label.png" alt="Logo image" style={{width: '100%'}} />
                    </div>
                    <div className="divider-custom-line"></div>
                </div>
                <div className="row gx-4 justify-content-center">
                    <div className="col-lg-7">
                        <h2 style={{marginBottom: '2rem'}}>Presentation</h2>
                        <p className="lead" style={{textAlign: 'justify'}}>
                            The luxury enterprise "Polo Cigars", is the box of 20 cigars of vitola toro, 54x6, denominated Ranch Collection.
                        </p>
                        <p className="lead" style={{textAlign: 'justify'}}>
                            It is an artisan work that pays tribute to the Benedicto ranch, owned by Don Apolinar Rodriguez, "Don Polo", having said ranch a sentimental or family meaning in the Rodriguez's Family, since in this ranch both the family, neighbors and friends congregated to collaborate with the typical tasks of tobacco harvests, their selection and sacred process.
                        </p>
                    </div>
                    <div className="col-lg-5" style={{zIndex: 1}}>
                        <div className="masthead-device-mockup" style={{marginTop: '3rem'}}>
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src="/assets/img/banners/3.png" alt="Banner image" style={{width: '100%'}} />                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="bg-light" id="services">
            <div className="container px-4">
                <div className="row gx-4 justify-content-center">
                    <div className="col-lg-4" style={{zIndex: 1}}>
                        <div className="masthead-device-mockup">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src="/assets/img/banners/4.png" alt="Cigar image" style={{width: '100%'}} />                            
                        </div>
                    </div>
                    <div className="col-lg-8">
                        {/* <h2 style={{marginBottom: '2rem'}}>Something without equal</h2> */}
                        <p className="lead" style={{textAlign: 'justify',marginBottom: '2rem'}}>
                            Said ranch was located at northeast in the island specifically in the "Monte Higuero" sector of the Moncion Municipality.
                        </p>
                        <p>The idea is taken to art and recorded by Mr. José Miguel Rodríguez as a tribute to said ranch, but the Ranch collection box is representative of all the cigar makers who have left their life history in a place shaped like a ranch since from the origins of the cigar, the ranch has been used to process tobacco in all parts of the world. The Benedicto “Ranch collection” collection box is created in okume wood, inside the creole cedar grills that protect and flavor the cigar, waterproof mdf sheets on the lid, embossed label and metal logo. It contains inside a processed tobacco leaf and a writing on paper that describes the story. it is a unique design and is available to cigar aficionados all over the world.</p>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}
export default Collection;