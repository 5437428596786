import React from 'react';
import { useNavigate } from 'react-router-dom';
const Footer = () => {
    const navigate = useNavigate()
    return (
        <footer className="bg-black text-center py-5">
            <div className="container px-5" style={{marginBottom: '100px'}}>
                <div className='row'>
                    <div className='col-md-3'>
                        <ul className="list-group">
                        <li style={{fontSize: '20px',padding: 0,textAlign: 'center'}} className="list-group-item bg-black main-text-color">Address</li>
                            <li className="list-group-item bg-black" style={{color: '#fff',textAlign: 'center',padding: 0}}>Santiago, Santiago De Los Caballeros</li>
                            <li className="list-group-item bg-black" style={{color: '#fff',textAlign: 'center',padding: 0}}>Dominican Republic</li>
                            <li className="list-group-item bg-black text-white" style={{padding: 0,textAlign: 'center'}}><i className="fa-solid fa-envelope"></i> <a style={{color: '#fff',textDecoration: 'underline'}} href="mailto:polocigars11@hotmail.com" target="_blank" rel="noreferrer">polocigars11@hotmail.com</a></li>
                            <li className="list-group-item bg-black" style={{color: '#000',padding: 0,marginLeft: '50%'}}></li>                            
                        </ul>
                    </div>
                    <div className='col-md-3'>
                        <ul className="list-group">
                            <li style={{fontSize: '20px',padding: 0,textAlign: 'center'}} className="list-group-item bg-black main-text-color">About</li>
                            <li className="list-group-item bg-black" style={{color: '#000',padding: 0,marginLeft: '50%'}}></li>
                            <li className="list-group-item bg-black text-white" style={{padding: 0,textAlign: 'center',textDecoration: 'underline',cursor: 'pointer'}} onClick={()=>navigate('/aboutus')}>About Polo Cigars</li>
                            <li className="list-group-item bg-black text-white" style={{color: '#000',padding: 0}}></li>
                            <li className="list-group-item bg-black text-white" style={{color: '#000',padding: 0}}></li>
                        </ul>
                    </div>
                    <div className='col-md-3'>
                        <ul className="list-group">
                            <li style={{fontSize: '20px',padding: 0,textAlign: 'center'}} className="list-group-item bg-black main-text-color">Polo Cigars</li>
                            <li className="list-group-item bg-black" style={{color: '#000',padding: 0,marginLeft: '50%'}}></li>
                            <li className="list-group-item bg-black text-white" style={{padding: 0,textAlign: 'center'}}><i className="fab fa-whatsapp"></i> <a style={{color: '#fff',textDecoration: 'underline'}} href="https://web.whatsapp.com/send?phone=18498636477" target="_blank" rel="noreferrer">849-863-6477</a></li>
                            <li className="list-group-item bg-black text-white" style={{padding: 0,textAlign: 'center'}}><i className="fa-solid fa-phone"></i> 809-746-6920</li>
                            {/* <li className="list-group-item bg-black text-white" style={{padding: 0,textAlign: 'center'}}><i className="fab fa-instagram"></i> <a style={{color: '#fff',textDecoration: 'underline'}} href="https://www.instagram.com/benedicto.cigars.oficial/" target="_blank" rel="noreferrer">polocigars</a></li> */}
                            <li className="list-group-item bg-black text-white" style={{color: '#000',padding: 0}}></li>
                            <li className="list-group-item bg-black text-white" style={{color: '#000',padding: 0}}></li>
                        </ul>
                    </div>
                    <div className='col-md-3'>
                        <li className="list-group-item bg-black text-white" style={{color: '#000',padding: 0}}>
                            <a style={{color: '#fff'}} href="https://www.instagram.com/benedicto.cigars.oficial/" target="_blank" rel="noreferrer"><i className="fab fa-instagram" style={{fontSize: '32px',margin: 15}}></i></a>
                            <i className="fab fa-facebook" style={{fontSize: '32px',margin: 15}}></i> 
                            <i className="fa-brands fa-twitter" style={{fontSize: '32px',margin: 15}}></i>
                            <i className="fa-brands fa-youtube" style={{fontSize: '32px',margin: 15}}></i>
                        </li>
                        <li className="list-group-item bg-black text-white" style={{color: '#000',padding: 0}}></li>
                        <li className="list-group-item bg-black text-white" style={{color: '#000',padding: 0}}></li>
                        <li className="list-group-item bg-black text-white" style={{color: '#000',padding: 0}}></li>
                    </div>
                </div>                
            </div>
            <div className="container px-5">
                <div className="text-white-50 small">
                    <div className="mb-2">&copy; Benedictocigars 2022. All Rights Reserved.</div>
                    <div className="mb-2">Made with <i className="fa-solid fa-heart" style={{color: '#f00 !important'}}></i> by <a style={{color: '#fff',textDecoration: 'underline'}} href="https://www.oshencore.com/" target="_blank" rel="noreferrer">OshenCore</a></div>
                    {/* <a href="#!">Privacy</a>
                    <span className="mx-1">&middot;</span>
                    <a href="#!">Terms</a>
                    <span className="mx-1">&middot;</span>
                    <a href="#!">FAQ</a> */}
                </div>
            </div>
        </footer>
    )
}
export default Footer;