import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
const currentDate = new Date(),
    ageDefault = {
        month: '1',
        day: 1,
        year: parseInt(currentDate.getFullYear()),
        remember: false
    }
const Age = (props) => {
    const [ageData,setAgeData] = useState(ageDefault),
        [validAge,setValidAge] = useState(true);
    let days = [],
        defaultYear = parseInt(currentDate.getFullYear()),
        lessYear = (defaultYear - 112),
        years = [];
    for(let i=1;i<=31;i++){
        days.push(i);
    }
    for(let i=defaultYear;i>=lessYear;i--){
        years.push(i);
    }
    const catchCheckBox = (input) => {
        const key = input.target.id;
        setAgeData({
            ...ageData,
            [key]: input.target.checked
        });
    }
    const catchData = (input) => {
        const key = input.target.id,
            value = input.target.value;
        setAgeData({
            ...ageData,
            [key]: value
        });
    }
    const setData = () => {
        const currentYear = parseInt(currentDate.getFullYear())/*,
            currentMonth = parseInt(currentDate.getMonth())*/;
        let years = currentYear - parseInt(ageData.year);
        if(years>=21)
        {
            if(ageData.remember){
                localStorage.setItem('_BENEDICTO_CIGAR_AGE_L_VERIFIED_',1);
            } else {
                sessionStorage.setItem('__BENEDICTO_CIGAR_AGE_S_VERIFIED_',1);
            }
            props.method(false);
        } else {
            setValidAge(false);
        }
    }
    return(
        <Modal
        show={props.show}
        onHide={()=>props.method(false)}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Body style={{paddingTop: '5rem',paddingBottom: '5rem'}}>
            <div className="container-fluid">
                <div className="row" style={{marginBottom: '2rem'}}>
                    <div className="col-md-12">
                        <h3>You must be 21 years old to visit this site.</h3>
                    </div>
                </div>
                <div className="row" style={{marginBottom: '1rem'}}>
                    <div className="col-md-12">
                        <h6>Please verify your age</h6>
                    </div>
                </div>
                {
                    !validAge?
                        <div className="alert alert-danger" role="alert">
                            Sorry, it doesn't look like you're old enough
                        </div>
                    :null
                }
                <div className="row">
                    <div className="form-group col-md-4">
                        <select onChange={catchData} id="month" className="form-control" style={{height: '3.5rem',borderRadius: '0'}}>
                            <option value='1'>JANUARY</option>
                            <option value='2'>FEBRUARY</option>
                            <option value='3'>MARCH</option>
                            <option value='4'>APRIL</option>
                            <option value='5'>MAY</option>
                            <option value='6'>JUNE</option>
                            <option value='7'>JULY</option>
                            <option value='8'>AUGUST</option>
                            <option value='9'>SEPTEMBER</option>
                            <option value='10'>OCTOBER</option>
                            <option value='11'>NOVEMBER</option>
                            <option value='12'>DECEMBER</option>
                        </select>
                    </div>
                    <div className="form-group col-md-4">
                        <select onChange={catchData} id="day" className="form-control" style={{height: '3.5rem',borderRadius: '0',textAlign: 'center'}}>
                            {
                                days.map((element,inx)=>{
                                    return <option key={inx} value={element}>{element}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group col-md-4">
                        <select onChange={catchData} id="year" className="form-control" style={{height: '3.5rem',borderRadius: '0',textAlign: 'center'}}>
                            {
                                years.map((element,inx)=>{
                                    return <option key={inx} value={element}>{element}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>          
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={()=>props.method(false)}>
            Close
          </Button> */}
          <input type="checkbox" id="remember" onChange={catchCheckBox} /><span>REMEMBER ME</span>
          <Button variant="dark" onClick={setData}>ENTER SITE</Button>
        </Modal.Footer>
      </Modal>
    );
}
export default Age;