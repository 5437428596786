import React from "react";
const Product = () => {
    return (
    <>
        <header className="masthead" style={{color: '#FFF'}}>
            <div className="container px-5 text-center">
                <h1 className="fw-bolder">Our Products</h1>
            </div>
        </header>
        {/* <section className="product-section">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center">
                    <div className="col-md-6">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/all.jpeg" alt="..." style={{width: '100%'}} /></div>
                    </div>
                </div>
            </div>
        </section> */}
        <section className="product-section">
            <div className="container px-4">
                <div className="divider-custom">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon" style={{width: '5rem'}}>
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img src="/assets/img/header_label.png" alt="Logo image" style={{width: '100%'}} />
                    </div>
                    <div className="divider-custom-line"></div>
                </div>
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-md-5">
                        <h2 className="display-1 lh-1 mb-4" style={{textAlign: 'center'}}>Ranch Collection</h2>
                        <p className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'justify'}}>
                            The luxury enterprise "Polo Cigars", is the box of 20 cigars of vitola toro, 54x6, denominated Ranch Collection.<br />
                            It is an artisan work that pays tribute to the Benedicto ranch, owned by Don Apolinar Rodriguez, "Don Polo", having said ranch a sentimental or family meaning in the Rodriguez's Family, since in this ranch both the family, neighbors and friends congregated to collaborate with the typical tasks of tobacco harvests, their selection and sacred process.
                        </p>
                    </div>
                    <div className="col-md-7">
                        <div className="px-1 px-sm-0"><img className="img-fluid rounded" src="/assets/img/banners/3.png" alt="..." style={{width: '100%',marginLeft: '5%'}} /></div>
                    </div>
                    {/* <div className="col-md-6 hide-in-mobile">
                        <h2 className="display-2 lh-1 mb-4" style={{textAlign: 'center'}}>Torpedo</h2>
                    </div> */}
                </div>
            </div>
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-md-7">
                        <h2 className="display-1 lh-1 mb-4" style={{textAlign: 'center'}}>Special Ranch Collection</h2>
                        <p className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'center'}}>A unique blend!</p>
                    </div>
                    <div className="col-md-5">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/e-2.png" alt="..." style={{width: '100%'}} /></div>
                    </div>
                    {/* <div className="col-md-6 hide-in-mobile">
                        <h2 className="display-2 lh-1 mb-4" style={{textAlign: 'center'}}>Torpedo</h2>
                    </div> */}
                </div>
            </div>            
        </section>
        <section className="product-section">
            <div className="container px-4">                
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-md-12">
                        <h2 className="display-1 lh-1" style={{textAlign: 'center',marginTop: '10px',marginBottom: '0px'}}>Box for 20 Benedictos</h2>
                    </div>                    
                    <div className="col-md-7">
                        {/* /assets/img/products/3.png */}
                        <div className="px-1 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/8.png" alt="..." style={{width: '100%'}} /></div>
                    </div>
                    <div className="col-md-5">                        
                        <div className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'center'}}>
                            <ul style={{textAlign: 'left', fontSize: '16px'}}>
                                <li><strong>Cigar Reviewed: </strong>Benedicto Robusto</li>
                                <li><strong>Country of Origin: </strong>Dominican Republic</li>
                                <li><strong>Factory: </strong>Polo Cigars</li>
                                <li><strong>Wrapper: </strong>Mexico (San Andrés Maduro)</li>
                                <li><strong>Binder: </strong>Dominican Republic (Negrito)</li>
                                <li><strong>Filler: </strong>Nicaragua & Dominican Republic (Criollo 98 Corojo Viso)</li>
                                <li><strong>Length: </strong>5 Inches</li>
                                <li><strong>Ring Guage: </strong>54</li>
                                <li><strong>Vitola: </strong>Robusto Extra</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="product-section">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-md-7">
                        <h2 className="display-1 lh-1 mb-4" style={{textAlign: 'center'}}>Amazing blend of Robusto</h2>
                        {/* <p className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'justify'}}>Amazing Blend!</p> */}
                    </div>
                    <div className="col-md-5">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/3.png" alt="..." style={{width: '100%'}} /></div>
                    </div>
                    {/* <div className="col-md-6 hide-in-mobile">
                        <h2 className="display-2 lh-1 mb-4" style={{textAlign: 'center'}}>Torpedo</h2>
                    </div> */}
                </div>
            </div>
        </section>
        <section className="product-section">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-md-12">
                        <h2 className="display-1 lh-1 mb-4 product-detail-header">Box for 10 Benedictos</h2>
                    </div>                    
                    <div className="col-md-7">
                        {/* /assets/img/products/1.png */}
                        <div className="px-1 px-sm-0">
                            <img className="img-fluid rounded" src="/assets/img/products/6.png" alt="..." style={{width: '100%'}} />
                        </div>
                    </div>
                    <div className="col-md-5">                        
                        <div className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'center'}}>
                            <ul style={{textAlign: 'left', fontSize: '16px'}}>
                                <li><strong>Cigar Reviewed: </strong>Benedicto Toro</li>
                                <li><strong>Country of Origin: </strong>Dominican Republic</li>
                                <li><strong>Factory: </strong>Polo Cigars</li>
                                <li><strong>Wrapper: </strong>Mexico (San Andrés Maduro)</li>
                                <li><strong>Binder: </strong>Dominican Republic (Negrito)</li>
                                <li><strong>Filler: </strong>Nicaragua & Dominican Republic (Criollo 98 Corojo Viso)</li>
                                {/* <li><strong>Length: </strong>5 Inches</li>
                                <li><strong>Ring Guage: </strong>54</li> */}
                                <li><strong>Vitola: </strong>Toro</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="product-section">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-md-7">
                        <h2 className="display-1 lh-1 mb-4" style={{textAlign: 'center'}}>Our brave blend, Toro</h2>
                        {/* <p className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'justify'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                    </div>
                    <div className="col-md-5">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/1.png" alt="..." style={{width: '100%'}} /></div>
                    </div>
                    {/* <div className="col-md-6 hide-in-mobile">
                        <h2 className="display-2 lh-1 mb-4" style={{textAlign: 'center'}}>Torpedo</h2>
                    </div> */}
                </div>
            </div>
        </section>
        <section className="product-section">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-md-7">
                        <h2 className="display-1 lh-1 mb-4" style={{textAlign: 'center'}}>Top quality! 'Torpedo' is the choose</h2>
                        {/* <p className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'justify'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                    </div>
                    <div className="col-md-5">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/2.png" alt="..." style={{width: '100%'}} /></div>
                    </div>
                    {/* <div className="col-md-6 hide-in-mobile">
                        <h2 className="display-2 lh-1 mb-4" style={{textAlign: 'center'}}>Torpedo</h2>
                    </div> */}
                </div>
            </div>
        </section>        
        {/* <section className="product-section">
            <div className="container px-4">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">                    
                    <div className="col-md-6">
                        <div className="px-5 px-sm-0"><img className="img-fluid rounded" src="/assets/img/products/4.png" alt="..." style={{width: '100%'}} /></div>
                    </div>
                    <div className="col-md-6">
                        <h2 className="display-2 lh-1 mb-4" style={{textAlign: 'center'}}>Robusto</h2>
                    </div>
                </div>
            </div>
        </section>         */}
    </>
    )
}
export default Product;