import React from "react";
const Snippet = () => {
    return (
        // <!-- Basic features section-->
        <section className="bg-light">
            <div className="container px-3">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-md-5">
                        <h2 className="display-4 lh-1 mb-4">The Greatest Cigar Ever Made!</h2>
                        <p className="lead fw-normal text-muted mb-5 mb-lg-0" style={{textAlign: 'justify'}}>Excellent cigars are more than just tobacco and smoke. Only a true craftsman, passionate and respectful of their work who can achieve a high distinction and flavor in their cigar.</p>
                    </div>
                    <div className="col-md-7">
                        {/* /assets/img/bg_view_2.jpeg */}
                        {/* rounded-circle */}
                        <div className="px-1 px-sm-0"><img className="img-fluid" src="/assets/img/banners/1.png" alt="..." /></div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Snippet;