import React from 'react';
import {
    HashRouter,
    Routes,
    Route
  } from 'react-router-dom';
// import ComingSoon from '../Components/comingSoon';
import Header from './header';
import Footer from './footer';
import NotFound from './notFound';
import Home from '../Components/home';
import AboutUs from '../Components/aboutUs';
import ContactUs from '../Components/contactUs';
import MainBrand from '../Components/mainBrand';
import Product from '../Components/product';
import Collection from '../Components/collection';
const Content = () => {
    return (
        <HashRouter>
            <Header />
                <Routes>
                    {/* <Route exact path="/" element={<ComingSoon />} /> */}
                    <Route exact path="/home" element={<Home />} />
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/aboutus" element={<AboutUs />} />
                    <Route exact path="/contactus" element={<ContactUs />} />
                    <Route exact path="/brand" element={<MainBrand />} />
                    <Route exact path="/products" element={<Product />} />
                    <Route exact path="/collection" element={<Collection />} />
                    <Route path="/notfound" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            <Footer />
        </HashRouter>
    )
}
export default Content;